import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-about-area',
  templateUrl: './about-area.component.html',
  styleUrls: ['./about-area.component.css']
})
export class AboutAreaComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }

}
