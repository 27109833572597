<app-breadcam-area></app-breadcam-area>
<app-about-area></app-about-area>
<app-about-info-area></app-about-info-area>
<app-about-main-info></app-about-main-info>
<app-kitchen></app-kitchen>
<!--todo add some information about the barbeque after we build it-->
<app-activities></app-activities>
<app-for-query></app-for-query>
<app-payment-methods></app-payment-methods>
<app-instagram-area></app-instagram-area>
