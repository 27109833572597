import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import {Activity} from '../model/activity';

@Injectable({
  providedIn: 'root'
})
export class ActivitiesService {
  activitiesURL = 'https://b9572fbc-8c98-47a4-8f03-340ae9b90def.mock.pstmn.io/accommodation-units/api/v1/accommodation-units';

  constructor(private httpClient: HttpClient) {
  }

  getActivities(){
    return this.httpClient.get<Activity[]>(this.activitiesURL);
  }
}
