<!-- forQuery_start -->
<div class="forQuery">
  <div class="container">
    <div class="row">
      <div class="col-xl-10 offset-xl-1 col-md-12">
        <div class="Query_border">
          <div class="row align-items-center justify-content-center">
            <div class="col-xl-6 col-md-6">
              <div class="Query_text">
                <p>Pentru rezervări sau detalii</p>
              </div>
            </div>
            <div class="col-xl-6 col-md-6">
              <div *ngFor="let contactInfo of contactInfos" class="phone_num">
                <a class="mobile_no" [href]="'tel:' + contactInfo.phoneNumber">{{contactInfo.contact}}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- forQuery_end-->
