<!-- footer -->
<footer class="footer">
  <div class="footer_top">
    <div class="container">
      <div class="row">
        <div>
          <div class="footer_widget">
            <h3 class="footer_title">
              adresa
            </h3>

            <div>
              <a class="bold-link" target="_blank" rel="noopener noreferrer"
                 href="https://www.google.com/maps/place/Cabana+Pa%C8%99cal%C4%83u/@46.6978841,23.0161501,17z/data=!3m1!4b1!4m5!3m4!1s0x0:0x5f07599df8a48769!8m2!3d46.6978841!4d23.0183388">
                sat Călățele Pădure nr 586A <br> Jud. Cluj, România
              </a>
            </div>


          </div>
        </div>

        <div>
          <div class="footer_widget">
            <h3 class="footer_title">
              Rezervare
            </h3>
            <div>
              <a class="bold-link" href="tel:+40757405523">+ 40 757 405 523 - Marius</a><br>
              <a class="bold-link" href="mailto:pascalaumarius3@gmail.com">pascalaumarius3@gmail.com</a>
            </div>
          </div>
        </div>

        <div>
          <div class="footer_widget">
            <h3 class="footer_title">
              Proiect UE
            </h3>
            <div class="ue">
              <a href="https://afir.info/" target="_blank" rel="noopener noreferrer">
                <img class="ue-logo" src="assets/img/logoAFIR.png" alt="UE_Logo">
              </a>
            </div>
          </div>
        </div>

<!--        <div>-->
<!--          <div class="footer_widget">-->
<!--            <h3 class="footer_title">-->
<!--              Navigare-->
<!--            </h3>-->
<!--            <ul>-->
<!--              <li>-->
<!--                <a style="cursor: pointer"-->
<!--                   class="bold-link"-->
<!--                   href=""-->
<!--                   (click)="navigateToHome()">-->
<!--                  Acasă-->
<!--                </a>-->
<!--              </li>-->
<!--              <li>-->
<!--                <a style="cursor: pointer"-->
<!--                   class="bold-link"-->
<!--                   (click)="navigateToRooms()">-->
<!--                  Camere-->
<!--                </a>-->
<!--              </li>-->
<!--              <li>-->
<!--                <a style="cursor: pointer"-->
<!--                   class="bold-link"-->
<!--                   (click)="navigateToAbout()">-->
<!--                  Despre-->
<!--                </a>-->
<!--              </li>-->
<!--              <li>-->
<!--                <a style="cursor: pointer"-->
<!--                   class="bold-link"-->
<!--                   (click)="navigateToContact()">-->
<!--                  Contact-->
<!--                </a>-->
<!--              </li>-->
<!--            </ul>-->
<!--          </div>-->
<!--        </div>-->

        <div class="social-media">
          <div class="footer_widget">
            <h3 class="footer_title">
              Social Media
            </h3>
            <ul class="social_links">
              <li *ngFor="let headerInfo of headerInfos">
                <a href="{{headerInfo.link}}" target="_blank" rel="noopener noreferrer">
                  <i id="{{headerInfo.id}}" class="fa {{headerInfo.classAttr}}"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>
