<!-- video_area_start -->
<div class="video_area video_bg overlay">
  <div class="video_area_inner text-center">
    <span>Peisaj din zonă</span>
    <h3>Relaxează-te și bucură-te de<br>
      Vacanță </h3>
<!--    todo change video url we have to create a promovation video with some drone or something veryy profesional and upload it on youtube-->
    <a class="video_btn popup-video" href="https://www.youtube.com/watch?v=vLnPwxZdW4Y">
      <i class="fa fa-play"></i>
    </a>
  </div>
</div>
<!-- video_area_end -->
