import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-offer',
  templateUrl: './offer.component.html',
  styleUrls: ['./offer.component.css']
})
export class OfferComponent implements OnInit {

  @Input()
  title: string;
  @Input()
  features: string[];
  @Input()
  imageSource: string;
  @Input()
  active: boolean;

  constructor(private router: Router) {
  }

  ngOnInit(): void {
  }

  navigateToContact() {
    this.router.navigate(['contact']);
    window.scroll(0, 0);
  }
}
