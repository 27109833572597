<!-- header-start -->
<header>
  <div class="header-area ">
    <div class="main-header-area" id="sticky-header">
      <div class="container-fluid p-0">
        <div class="row align-items-center no-gutters">
          <div class="col-xl-5 col-lg-6">
            <div class="main-menu  d-none d-lg-block">
              <nav>
                <ul id="navigation">
                  <li>
                    <a href="" [ngClass]="isActive('/home') ? 'active' : ''"
                       style="cursor: pointer"
                       (click)="navigateToHome()">
                      acasă
                    </a>
                  </li>
                  <li>
                    <a [ngClass]="isActive('/rooms') ? 'active' : ''"
                       style="cursor: pointer"
                       href="/rooms"
                       (click)="navigateToRooms()">
                      camere
                    </a>
                  </li>
                  <li>
                    <a [ngClass]="isActive('/about') ? 'active' : ''"
                       style="cursor: pointer"
                       href="/about"
                       (click)="navigateToAbout()">
                      despre
                    </a>
                  </li>
                  <li>
                    <a [ngClass]="isActive('/contact') ? 'active' : ''"
                       style="cursor: pointer"
                       href="/contact"
                       (click)="navigateToContact()">
                      Contact
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>

          <div class="col-xl-2 col-lg-2">
            <div class="logo-img">
              <a href="" (click)="navigateToHome()">
                <img id="logo-header" class="logo-img-file" alt="" src="../../../assets/img/logo.png">
              </a>
            </div>
          </div>


          <div class="col-xl-5 col-lg-4 d-none d-lg-block">
            <div class="book_room">
              <div class="social_links">
                <ul>
                  <li *ngFor="let headerInfo of headerInfos">
                    <a href="{{headerInfo.link}}" target="_blank" rel="noopener noreferrer">
                      <i id="{{headerInfo.id}}" class="fa {{headerInfo.classAttr}}"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="mobile_menu d-block d-lg-none"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>
<!-- header-end -->
