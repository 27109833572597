<div class="offers_area padding_top">
  <div class="container">
    <div class="row">
      <div class="col-xl-12">
        <div class="section_title text-center mb-100">
          <span>Ofertele noastre</span>
          <h3>Oferte active</h3>
        </div>
      </div>
    </div>
    <div class="row">
      <app-offer class="col-xl-4 col-md-4" [title]="'Week-end de la 2000 RON'"
                 [imageSource]="'../../../assets/img/offers/oferta.jpeg'"
                 [features]="['Baie la fiecare camera','Minim 11 persoane', 'Bucătărie modernă', 'Living spațios', 'Peisaj de vis']"
                 [active]="true"></app-offer>


      <app-offer class="col-xl-4 col-md-4" [title]="'Revelion de la 10000 RON'"
                 [imageSource]="'../../../assets/img/offers/revelion.jpeg'"
                 [features]="['Baie la fiecare camera','Minim 11 persoane', 'Bucătărie modernă', 'Living spațios', 'Pârtie de ski in apropiere']"
                 [active]="true"></app-offer>


      <app-offer class="col-xl-4 col-md-4" [title]="'Craciun de la 5000 RON'"
                 [imageSource]="'../../../assets/img/offers/Christmas.jpg'"
                 [features]="['Baie la fiecare camera','Minim 11 persoane', 'Bucătărie modernă', 'Living spațios', 'Pârtie de ski in apropiere']"
                 [active]="true"></app-offer>
    </div>
  </div>
</div>
