import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-slider-area',
  templateUrl: './slider-area.component.html',
  styleUrls: ['./slider-area.component.css']
})
export class SliderAreaComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }

}
