import {Component, OnInit} from '@angular/core';
import {ContactInfo} from './ContactInfo';

@Component({
  selector: 'app-for-query',
  templateUrl: './for-query.component.html',
  styleUrls: ['./for-query.component.css']
})
export class ForQueryComponent implements OnInit {

  contactInfos: ContactInfo[] = [
    new ContactInfo('+ 40 757 405 523 - Marius', '+40757405523'),
    new ContactInfo('+ 40 746 784 025 - Ionuț', '+40746784025'),
    new ContactInfo('+ 40 774 083 680 - Marinela', '+40774083680')
  ];

  constructor() {
  }

  ngOnInit(): void {
  }

}
