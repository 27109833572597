import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-offers-area',
  templateUrl: './offers-area.component.html',
  styleUrls: ['./offers-area.component.css']
})
export class OffersAreaComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }

}
