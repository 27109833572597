export class HeaderInfo {
  link: string;
  id: string;
  classAttr: string;


  constructor(link: string, id: string, classAttr: string) {
    this.link = link;
    this.id = id;
    this.classAttr = classAttr;
  }
}
