import {Component, OnInit} from '@angular/core';
import {NgxGalleryImage, NgxGalleryOptions} from '@kolkov/ngx-gallery';
import {GalleryOptions} from '../../gallery/GalleryOptions';

@Component({
  selector: 'app-kitchen',
  templateUrl: './kitchen.component.html',
  styleUrls: ['./kitchen.component.css']
})
export class KitchenComponent implements OnInit {
  galleryOptions: NgxGalleryOptions[];
  galleryImages: NgxGalleryImage[];
  customGalleryOptions: GalleryOptions;

  constructor() {
    this.customGalleryOptions = new GalleryOptions();
  }

  ngOnInit(): void {
    this.galleryOptions = this.customGalleryOptions.getGalleryOptions();

    this.galleryImages = [
      {
        small: 'assets/img/kitchen/1.jpeg',
        medium: 'assets/img/kitchen/1.jpeg',
        big: 'assets/img/kitchen/1.jpeg',
      },
      {
        small: 'assets/img/kitchen/2.jpeg',
        medium: 'assets/img/kitchen/2.jpeg',
        big: 'assets/img/kitchen/2.jpeg',
      },
      {
        small: 'assets/img/kitchen/3.jpeg',
        medium: 'assets/img/kitchen/3.jpeg',
        big: 'assets/img/kitchen/3.jpeg',
      },
      {
        small: 'assets/img/kitchen/4.jpeg',
        medium: 'assets/img/kitchen/4.jpeg',
        big: 'assets/img/kitchen/4.jpeg',
      },
      {
        small: 'assets/img/kitchen/5.jpg',
        medium: 'assets/img/kitchen/5.jpg',
        big: 'assets/img/kitchen/5.jpg',
      },
      {
        small: 'assets/img/kitchen/7.jpg',
        medium: 'assets/img/kitchen/7.jpg',
        big: 'assets/img/kitchen/7.jpg',
      }
    ];
  }

}
