<div class="instragram_area">
  <div *ngFor="let imageInformation of instagramImageInformation" class="single_instagram">
    <img alt="" src="{{imageInformation.source}}">
    <div class="overlay">
      <a href="{{imageInformation.link}}" target="_blank" rel="noopener noreferrer">
        <i [ngClass]="imageInformation.platform === 'instagram' ? 'fa fa-instagram' : 'fa fa-facebook'"></i>
      </a>
    </div>
  </div>
</div>
