<!-- about_main_info_start -->
<div class="about_main_info">
  <div class="container">
    <div class="row">
      <div class="single_about_info">
        <h3>Perfectă pentru evenimente speciale</h3>
        <p>Fie că ai nevoie de un loc pentru majorat sau o petrecere de ziua ta, pensiunea Steaua Apusenilor este
          locul potrivit pentru astfel de evenimente. Iar dacă nu este o ocazie atât de
          specială, poți veni pe perioada unui week-end să te relaxezi.
        </p>
      </div>

      <div class="single_about_info">
        <h3>Accesul cu mașina</h3>
        <p>Drumul până la pensiune este asfaltat și accesul se poate realiza cu orice tip de mașină. Odată ajunși la
          pensiune mașinile pot fi parcate in curte unde este loc suficient. Tot asfaltat este și drumul care duce la
          lacul Beliș si pârtiile de ski Mărișel care se afla la aproximativ 8 km de pensiune.
        </p>
      </div>
    </div>
  </div>
</div>
<!-- about_main_info_end -->
