import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-payment-method',
  templateUrl: './payment-method.component.html',
  styleUrls: ['./payment-method.component.css']
})
export class PaymentMethodComponent implements OnInit {

  @Input()
  title: string;
  @Input()
  paymentTypes: string[];
  @Input()
  imageSource: string;

  constructor() {
  }

  ngOnInit(): void {
  }

}
