import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-payment-methods',
  templateUrl: './payment-methods.component.html',
  styleUrls: ['./payment-methods.component.css']
})
export class PaymentMethodsComponent implements OnInit {

  voucherPaymentType: string[] = ['Suport de hartie', 'Suport electronic (card)'];

  constructor() {
  }

  ngOnInit(): void {
  }

}
