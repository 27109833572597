export class ImageInformation {
  source: string;
  link: string;
  platform: string;

  constructor(source: string, link: string, platform: string) {
    this.source = source;
    this.link = link;
    this.platform = platform;
  }
}
