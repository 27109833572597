<div class="about_main_info">
  <div class="container">
    <div class="row">
      <div class="single_about_info">
        <h3>Bucătăria</h3>
        <p>Este dotată cu toate utilitățile necesare:</p>
        <ul>
          <li>Cuptor cu microunde</li>
          <li>Cuptor electric</li>
          <li>Plită pe gaz cu aprindere electrică</li>
          <li>Prăjitor de pâine</li>
          <li>Sandwich-maker</li>
          <li>Mașină de spălat vase</li>
          <li>Frigider</li>
          <li>Combină Frigorifică</li>
        </ul>
      </div>
    </div>
  </div>

  <ngx-gallery [options]="galleryOptions"
               [images]="galleryImages"
               class="ngx-gallery">
  </ngx-gallery>
</div>
