import {Component, OnInit} from '@angular/core';
import {ImageInformation} from './ImageInformation';

@Component({
  selector: 'app-instagram-area',
  templateUrl: './instagram-area.component.html',
  styleUrls: ['./instagram-area.component.css']
})
export class InstagramAreaComponent implements OnInit {

  instagramImageInformation: ImageInformation[] = [
    new ImageInformation('../../../assets/img/instagram/insta1.jpg', 'https://www.instagram.com/p/CMowXVWjVvG/', 'instagram'),
    new ImageInformation('../../../assets/img/instagram/insta2.jpg', 'https://www.instagram.com/p/B6vNpKxHf1U/', 'instagram'),
    new ImageInformation('../../../assets/img/instagram/insta3.jpg', 'https://www.instagram.com/p/B6vNHUyH38A/', 'instagram'),
    new ImageInformation('../../../assets/img/instagram/insta4.jpg', 'https://www.instagram.com/p/CMowXVWjVvG/', 'instagram'),
    new ImageInformation('../../../assets/img/instagram/insta5.jpg', 'https://www.instagram.com/p/CMowOnyjCPC/', 'instagram'),
    new ImageInformation('../../../assets/img/instagram/fb1.jpg', 'https://www.facebook.com/Pensiunea-Steaua-Apusenilor-112229230291283/photos/a.112229286957944/338861784294692', 'facebook'),
    new ImageInformation('../../../assets/img/instagram/fb2.jpg', 'https://www.facebook.com/Pensiunea-Steaua-Apusenilor-112229230291283/photos/a.112305360283670/338864904294380', 'facebook'),
    new ImageInformation('../../../assets/img/instagram/fb3.jpg', 'https://www.facebook.com/Pensiunea-Steaua-Apusenilor-112229230291283/photos/a.112305360283670/112305376950335', 'facebook'),
    new ImageInformation('../../../assets/img/instagram/fb4.jpg', 'https://www.facebook.com/Pensiunea-Steaua-Apusenilor-112229230291283/photos/a.112305360283670/112305473616992', 'facebook'),
    new ImageInformation('../../../assets/img/instagram/fb5.jpg', 'https://www.facebook.com/Pensiunea-Steaua-Apusenilor-112229230291283/photos/a.112303743617165/112303746950498', 'facebook')
  ];

  constructor() {
  }

  ngOnInit(): void {
  }

}
