<div class="" style="z-index: 0">
  <img *ngIf="!active" class="xMark" src="../../../../assets/img/offers/x_mark.png">
  <div [ngClass]="active ? 'single_offers' : 'single_offers expired'">
    <div class="about_thumb">
      <img alt="" src="{{imageSource}}">
    </div>
    <h3>{{title}}</h3>
    <ul>
      <li [ngClass]="active?'': 'expiredFeature'" *ngFor="let feature of features">{{feature}} </li>
    </ul>
    <a class="book_now" (click)="active ? navigateToContact() : ''">Contactează-ne</a>
  </div>
</div>
