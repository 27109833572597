<!-- about_area_start -->
<div class="about_area">
  <div class="container">
    <div class="row">
      <div class="col-xl-5 col-lg-5">
        <div class="about_info">
          <div class="section_title mb-20px">
            <span>Despre noi</span>
            <h3>O pensiune modernă
              în natură</h3>
          </div>
          <p>Pensiunea Steaua Apusenilor dispune de 4 dormitoare cu baie proprie(11 persoane), un living de aproximativ 30
            m<sup>2</sup>,
            o sală de mese de aproximativ 20 m<sup>2</sup>, baie utilitară pentru living și sala de mese, o bucătărie
            modernă
            și încalzirea este realizată cu ajutorul unei centrale pe lemne</p>
        </div>
      </div>
      <div class="col-xl-7 col-lg-7">
        <div class="about_thumb d-flex">
          <div class="img_1">
            <img alt="" src="../../../assets/img/about/scara.jpg">
          </div>
          <div class="img_2">
            <img alt="" src="../../../assets/img/about/dormitor.png">
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- about_area_end -->
