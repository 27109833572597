import {Component, OnInit} from '@angular/core';
import {Activity} from '../../model/activity';
import {ActivitiesService} from '../../services/activities.service';

@Component({
  selector: 'app-activities',
  templateUrl: './activities.component.html',
  styleUrls: ['./activities.component.css']
})
export class ActivitiesComponent implements OnInit {
  activities: Activity[];

  constructor(private activitiesService: ActivitiesService) {
  }

  ngOnInit(): void {
    this.setActivities();
  }

  setActivities() {
    this.activitiesService.getActivities().subscribe(value => this.activities = value);
  }
}
