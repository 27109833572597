import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {HeaderInfo} from '../header/HeaderInfo';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  headerInfos: HeaderInfo[] = [
    new HeaderInfo('https://www.facebook.com/Cabana-Pa%C8%99cal%C4%83u-112229230291283', 'fb-logo', 'fa-facebook-square'),
    // tslint:disable-next-line:max-line-length
    new HeaderInfo('https://www.google.com/maps/place/Cabana+Pa%C8%99cal%C4%83u/@46.6978841,23.0161501,17z/data=!3m1!4b1!4m5!3m4!1s0x0:0x5f07599df8a48769!8m2!3d46.6978841!4d23.0183388', 'maps-logo', 'fa-maps-square'),
    new HeaderInfo('https://www.instagram.com/steauaapusenilor/', 'insta-logo', 'fa-instagram')
    // new HeaderInfo('https://www.youtube.com/channel/UCefDIUITh-nFCaxyjF3XZLg?view_as=subscriber', 'youtube-logo', 'fa-youtube-square')
  ];

  constructor(private  router: Router) {
  }

  ngOnInit(): void {
  }

  navigateToRooms() {
    this.router.navigate(['rooms']);
    window.scroll(0, 0);
  }

  navigateToHome() {
    this.router.navigate(['home']);
    window.scroll(0, 0);
  }

  navigateToAbout() {
    this.router.navigate(['about']);
    window.scroll(0, 0);
  }

  navigateToContact() {
    this.router.navigate(['contact']);
    window.scroll(0, 0);
  }
}
