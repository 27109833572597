<section class="contact-section">
  <div class="container">
    <div class=" mb-5 pb-4">
      <agm-map [latitude]="mapLatitude" [longitude]="mapLongitude" [zoom]="zoom" [gestureHandling]="'cooperative'"
               [scrollwheel]="null">
        <agm-marker [latitude]="markerLatitude" [longitude]="markerLongitude" [iconUrl]="icon"
                    [title]="title" [animation]="animation" [openInfoWindow]="true"
                    (markerClick)="onMarkerClick()">
          <agm-info-window [disableAutoPan]="false">
            <div id="content">

              <div id="siteNotice"></div>

              <h1 id="firstHeading" class="firstHeading">Pensiunea Steaua Apusenilor</h1>

              <div id="bodyContent">
                <p>
                  <i class="fa fa-maps-square"></i>
                  <a class="info-window-link"
                     target="_blank" rel="noopener noreferrer"
                     href="https://www.google.com/maps/dir//Cabana+Pa%C8%99cal%C4%83u,+Str.+Principal%C4%83,+Nr.+586A+C%C4%83l%C4%83%C8%9Bele-P%C4%83dure,+407135/@46.6978841,23.0161501,17z/data=!4m9!4m8!1m0!1m5!1m1!1s0x4748e7a26f61d249:0x5f07599df8a48769!2m2!1d23.0183388!2d46.6978841!3e0">
                    Indicații de orientare</a> sau
                  <a class="info-window-link"
                     target="_blank" rel="noopener noreferrer"
                     href="https://www.google.com/maps/place/Cabana+Pa%C8%99cal%C4%83u/@46.6978841,23.0161501,17z/data=!3m1!4b1!4m5!3m4!1s0x0:0x5f07599df8a48769!8m2!3d46.6978841!4d23.0183388">
                    vezi poziția</a>
                </p>

                <p>
                  <i class="fa fa-facebook-square"></i>

                  <a class="info-window-link"
                     target="_blank" rel="noopener noreferrer"
                     href="https://www.facebook.com/Cabana-Pa%C8%99cal%C4%83u-112229230291283">
                    Vizualizează pagina de Facebook
                  </a>
                </p>

                <p>
                  <i class="fa fa-instagram"></i>
                  <a class="info-window-link"
                     target="_blank" rel="noopener noreferrer"
                     href="https://www.instagram.com/cabanapascalau/">
                    Urmărește-ne pe Instagram
                  </a>
                </p>
              </div>
            </div>
          </agm-info-window>
        </agm-marker>
      </agm-map>
    </div>

    <div class="row">
      <div class="col-12 contact-us-section-title">
        <h2 class="contact-title">Contactați-ne</h2>
      </div>

      <div class="contact-info-container">
        <div class="media contact-info">
          <span class="contact-info__icon"><i class="ti-home"></i></span>
          <div class="media-body">
            <h3>
              <a id="contact-location"
                target="_blank" rel="noopener noreferrer"
                href="https://www.google.com/maps/place/Cabana+Pa%C8%99cal%C4%83u/@46.6978841,23.0161501,17z/data=!3m1!4b1!4m5!3m4!1s0x0:0x5f07599df8a48769!8m2!3d46.6978841!4d23.0183388">
                sat Călățele Pădure, nr 586A <br><br>
                jud. Cluj, România
              </a>
            </h3>
          </div>
        </div>

        <div class="media contact-info">
          <span class="contact-info__icon"><i class="ti-tablet"></i></span>
          <div class="media-body">
            <h3><a id="contact-tel1" href="tel:+40757405523">+ 40 757 405 523 - Marius</a><br><br>
              <a id="contact-tel2" href="tel:+40746784025">+ 40 746 784 025 - Ionut</a><br><br>
              <a id="contact-tel3" href="tel:+40774083680">+ 40 774 083 680 - Marinela</a><br><br>
            </h3>
          </div>
        </div>

        <div class="media contact-info">
          <span class="contact-info__icon">
            <i class="ti-email"></i>
          </span>
          <div class="media-body">
            <h3>
              <a id="contact-email" href="mailto:pascalaumarius3@gmail.com">pascalaumarius3@gmail.com</a>
            </h3>
            <p>Trimite-ți cererile voastre oricând</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
