import {Component, OnInit} from '@angular/core';
import {NgxGalleryImage, NgxGalleryOptions} from '@kolkov/ngx-gallery';
import {GalleryOptions} from '../../gallery/GalleryOptions';

@Component({
  selector: 'app-about-info-area',
  templateUrl: './about-info-area.component.html',
  styleUrls: ['./about-info-area.component.css']
})
export class AboutInfoAreaComponent implements OnInit {
  galleryOptions: NgxGalleryOptions[];
  galleryImages: NgxGalleryImage[];
  customGalleryOptions: GalleryOptions;

  constructor() {
    this.customGalleryOptions = new GalleryOptions();
  }

  ngOnInit(): void {
    this.galleryOptions = this.customGalleryOptions.getGalleryOptions();

    this.galleryImages = [
      {
        small: 'assets/img/about/about1.jpeg',
        medium: 'assets/img/about/about1.jpeg',
        big: 'assets/img/about/about1.jpeg',
      },
      {
        small: 'assets/img/about/about2.jpeg',
        medium: 'assets/img/about/about2.jpeg',
        big: 'assets/img/about/about2.jpeg',
      },
      {
        small: 'assets/img/about/about3.png',
        medium: 'assets/img/about/about3.png',
        big: 'assets/img/about/about3.png',
      },
      {
        small: 'assets/img/about/about4.png',
        medium: 'assets/img/about/about4.png',
        big: 'assets/img/about/about4.png',
      },
      {
        small: 'assets/img/about/about5.jpeg',
        medium: 'assets/img/about/about5.jpeg',
        big: 'assets/img/about/about5.jpeg',
      },
      {
        small: 'assets/img/about/about6.jpeg',
        medium: 'assets/img/about/about6.jpeg',
        big: 'assets/img/about/about6.jpeg',
      },
      {
        small: 'assets/img/about/about7.jpeg',
        medium: 'assets/img/about/about7.jpeg',
        big: 'assets/img/about/about7.jpeg',
      },
      {
        small: 'assets/img/about/about8.jpg',
        medium: 'assets/img/about/about8.jpg',
        big: 'assets/img/about/about8.jpg',
      },
      {
        small: 'assets/img/about/about9.jpg',
        medium: 'assets/img/about/about9.jpg',
        big: 'assets/img/about/about9.jpg',
      },
      {
        small: 'assets/img/about/about10.jpg',
        medium: 'assets/img/about/about10.jpg',
        big: 'assets/img/about/about10.jpg',
      },
      {
        small: 'assets/img/about/afir.jpeg',
        medium: 'assets/img/about/afir.jpeg',
        big: 'assets/img/about/afir.jpeg',
      }
    ];
  }

}
