import {Component, OnInit} from '@angular/core';
import {AgmMarker} from '@agm/core';

@Component({
  selector: 'app-contact-section',
  templateUrl: './contact-section.component.html',
  styleUrls: ['./contact-section.component.css']
})
export class ContactSectionComponent implements OnInit {
  zoom = 10;
  mapLatitude = 46.73;
  mapLongitude = 23.3;
  markerLatitude = 46.697886;
  markerLongitude = 23.018342;
  animation = 'BOUNCE';
  title = 'Click me';
  opacity = 1.0;
  icon = {
    url: 'assets/img/Favicon.png',
    scaledSize: {
      height: 40,
      width: 45.6
    }
  };

  constructor() {
  }

  ngOnInit(): void {
    if (window.innerWidth < 770) {
      this.zoom = 11;
      this.mapLatitude = 46.7;
      this.mapLongitude = 23.05;
    }
  }

  onMarkerClick() {
    this.animation = 'DROP';
  }
}
